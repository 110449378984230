// React root container
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

// Global link cursor
a {
    cursor: pointer;
}
.demo-row-odd{
  border-bottom: 2px solid red;
}


.bg-blue{
  background-color: get($aside-menu-config, base, main-blue);
  color:white;

}
.pageTitle {
  font-size: 20px;
  color: get($aside-menu-config, base, main-blue);
  font-family: $font-family-base;
  font-weight: 600;
}

.pageSubtitle {
  font-size: 25px!important;
  color: get($aside-menu-config, base, main-blue)!important;
  font-family: $font-family-base!important;
  font-weight: 600!important;
}

.pageDetails {
  font-size: 15px!important;
  color: get($aside-menu-config, base, main-blue)!important;
  font-family: $font-family-base!important;
  font-weight: 500!important;
  span{
    font-weight: 400;
    font-size: 10px;
    color :rgba(126, 130, 153, 0.6);
  }
}

.light-txt{
  font-weight: 400;
  font-size: 14px;
  color :rgba(126, 130, 153, 0.6);
}

.dashboard-item {
  border: 1px solid get($aside-menu-config, base, main-blue);
  color: black;

  &:hover{
    color:get($aside-menu-config, base, main-blue);
  }
  &--variant {
    border: 1px solid white;
    background-color: get($aside-menu-config, base, main-blue);
    color: white;
    &:hover{
      color: whitesmoke;
    }
    >p {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      margin: auto;
    }
    >span{
      font-size: 35px;
      color: white;
      font-weight: 700;
    }
  }
  >p {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin: auto;
    text-decoration: none;

  }
  >span{
    font-size: 35px;
    color: get($aside-menu-config, base, main-blue);
    font-weight: 700;
  }
}

.mui-ripple-circle {display: none}

.no-gutter > [className*='col-'] {
  padding-right:30px;
  padding-left:0;
}

// Auth form
.MuiInput-underline:focus:not(.Mui-focused):before,
.MuiInput-underline:active:not(.Mui-focused):before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #5867dd !important;
}

// builder
.builder-tabs {
  .MuiTabs-flexContainer {
    button {
      margin-right: 20px;
      padding: 18px 0;
      min-width: auto;
      text-transform: none;
      font-size: 1rem;
    }
  }

  .PrivateTabIndicator-colorSecondary-64 {
    background-color: #5867dd;
  }
}

.MuiFormControl-marginNormal { width: 100%; }

.MuiSwitch-colorSecondary.Mui-checked {
  color: #5867dd !important;

  &:hover {
    background-color: rgba(88, 103, 221, 0.08) !important;
  }

  & + .MuiSwitch-track {
    background-color: #5867dd !important;
  }
}

// Partials
// notice
.notice__paragraph {
    font-weight: 400;
    font-size: 17px;
}

// animated-loading
.header-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}


// quick-actions-panel
.MuiBackdrop-root {
  background: rgba(0,0,0,.05) !important;
}

// Demos examples toolbar
.kt-comming-soon {
  .kt-demo-panel__item-preview-overlay {
    opacity: 1 !important;
  }
}

// user-notifications
.tab-content {
  background-color: #fff;
}

// Material UI
// For Select component
.react-select {
  .MuiInputBase-input {
    display: flex;

    .css-1okebmr-indicatorSeparator {
      margin: 0;
    }

    & > div:first-child {
      flex: 1;
      .MuiTypography-colorTextSecondary {
        left: 2px;
        bottom: 6px;
        position: absolute;
        font-size: 16px;
      }
      input {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.5;
        font-size: 16px;
      }
    }
  }
  .MuiInputBase-root {
    font-size: 1.4rem;
  }
}
.select_input-h {
  .MuiSelect-select { height: 41px; box-sizing: border-box;}
}

.MuiPaper-elevation1 {
  box-shadow: 0 0 50px 0 rgba(82,63,105,.15) !important;
}

.jodit-wysiwyg{
  min-height: 300px!important;
}
.mission-card:hover{
  cursor: pointer;
}
.mission-card{
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 2px solid get($aside-menu-config, base, main-blue);;
  border-radius: 5px;
  >.mission-card-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    svg{
      padding: 30px 0 0 0;
      width: auto;
      height: 80%;
    }
    >h3{
      padding: 0 50px 20px 50px !important;
            color: get($aside-menu-config, base, main-blue);
    }
    >p{
      padding: 0 20px 20px 20px !important;
      font-size: 16px;
      margin-bottom: 40px;
    }
    .icon-wrapper{
      max-height: 200px;
    }
  }
  >.mission-card-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: get($aside-menu-config, base, main-blue);
    color: white;
    font-size: 16px;
    text-align: center;
    min-height: 45px;
    bottom: 0;
    position: absolute;
    width: 100%;
    margin-top: 20px;
  }
}

.wizzard-header{


  .wizzard-breadcrumb{
    border-bottom: 5px solid rgba(126, 130, 153, 0.6);
    p{
      font-weight: 500;
      font-size: 14px;
      margin: 0;
     color :rgba(126, 130, 153, 0.6);
    }
    span{
      font-size: 20px;
      font-weight: bolder;
      color:rgba(126, 130, 153, 0.6);
  
    }
  }

  .wizzard-breadcrumb.active{
    border-bottom: 5px solid get($aside-menu-config, base, main-blue);


    p{
      color: get($aside-menu-config, base, main-blue);

    }
    span{
      font-size: 20px;
      font-weight: bolder;
      color: get($aside-menu-config, base, main-blue);

    }
  }
}

.mission-form{
  .group-title{
    font-size: 20px;
    color: get($aside-menu-config, base, main-blue);
    font-family: $font-family-base;
    font-weight: 600;
  }
  .required-desc{
      color:black;
      
  }
}
.asterisk{
  color: red!important;
}
.custom-counter {
  font-weight: 700;
  font-size: 32pt;
  margin: -10px 0px 0px 5px;
}

.custom_counter_stats {
  font-weight: 700;
  font-size: 18pt;
  margin: -10px 0px 0px 5px;
}

.min-h-150 {
  min-height: 150px;
}

.min-h-300{
  min-height: 300px;
}

.dashboard-toggle {
  display: block !important;
}

.dashboard-toggle-item .page-link {
  background-color: #EEE5FF !important;
  border-color: #EEE5FF !important;
  border-radius: 0.42rem;
  margin-bottom: 5px;
}

.dashboard-toggle-item.active .page-link {
  background-color: #8950FC !important;
  border-color: #8950FC !important;
}

.table-column-twolines {
  display: flex;
  flex-direction: column;
  text-align: right;
  max-width: 150px;
}

.max-h-600 {
  max-height: 600px;
}

.rc-time-picker{
  margin: 0!important;
  padding:  0!important;
}

.rc-time-picker-input{
  display: block!important;
  width: 100%!important;
  height: calc(1.5em + 1.3rem + 2px)!important;
  padding: 0.65rem 1rem!important;
  font-size: 1rem!important;
  font-weight: 400!important;
  line-height: 1.5!important;
  color: #3F4254!important;
  background-color: #ffffff!important;
  background-clip: padding-box!important;
  border: 1px solid #E4E6EF!important;
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
  border-top-right-radius: 0.42rem!important;
  border-bottom-right-radius: 0.42rem!important;
  box-shadow: none!important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out!important;
}

.rc-time-picker-clear-icon{
  display: none
}

.extra-remuneration {
 color: get($aside-menu-config, base, main-blue);
 font-weight: 600;
  &:hover{
    cursor: pointer;
  }
}
p.extra-remuneration {
  margin: 0;
  margin-left: 10px;
}
i.extra-remuneration {
  margin: 0
}

.step-buttons {
  position: absolute!important;
  bottom: 30px!important;
}

.step-buttons-lg {
  position: relative;
  bottom: -30px!important;
}

.offer-review-container {
  display: flex;
  flex-direction: column;
  .bordered{
    border: 1px solid gray;
    padding: 20px;
  }
  .block-title{
    color: black;
    font-weight: 600;
  }
}

.icon-blue {
  color:  get($aside-menu-config, base, main-blue);
}

.width-100 .react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper{
  width: 100%;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.react-datepicker-wrapper input{
  width:100%;
}

.radius-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

textarea.form-control{
  min-height: 50px;
}

textarea.form-control.lg{
  min-height: 80px;
  height: auto;
}

textarea.form-control.md{
  min-height: 50px;
  height: auto;
}

.width-full {
  width:100%;
}

.text-justify {
  text-align: justify;
}

.large-icon {
    padding-top: 30px;
    color: #3165A7;
    font-size: 120px;
}

.blue-gradiant-background {
  background-color:  get($aside-menu-config, base, main-blue);
  background-image: radial-gradient(circle,rgba(225,240,255,.4),#3165A7 90%);
  padding: 0;
}

.salary-graph {
  border-radius: 5px;
  .salary-value {
    font-size: 20px;
    color: black;
    font-weight: 800;
  }
  .salary-title {
    margin-top: 10px;
  }
}



.grey-background {
  background-color: white;
  border-radius: 5px;
  border: 2px solid get($aside-menu-config, base, main-blue);
}

.similar-salary-title{
  color: white;
  font-size: 16px;
  margin: 10px;
}
.dropdown-menu-limited {
  margin-right: -0.8rem;
  /*rtl:ignore*/
  margin-left: 65px;
  height: 450px;
  overflow-y: scroll;
}

.react-bootstrap-table-page-btns-ul{
  justify-content: flex-end!important;
}

.text-blue {
  color: #3165A7 !important;
}
.cursor-hand {
  cursor: pointer;
}

.ta-left {
  text-align: left;
}

.under-background{
  background: url(/media/bg/under-construction.jpg);
  background-position-x: left;
  background-size: 100%;
  background-repeat: no-repeat;
  min-width:34% !important;
}

.under-div {
  min-height: 600px;
}

.under-div .error-title {
  font-size: 14rem !important;
}

.box-shadow-interimaire {
  box-shadow:0 9px 16px 0 rgba(204,41,54,.25)!important;
}

.box-shadow-primary {
  box-shadow:0 9px 16px 0 rgba(54,153,255,.25)!important;
}

.box-shadow-success {
  box-shadow:0px 9px 16px 0px rgba(27, 197, 189, 0.25) !important;
}

.box-shadow-info {
  box-shadow:0px 9px 16px 0px rgba(137, 80, 252, 0.25) !important;
}

.ribbon-right {
  top: -2px; 
  right: 20px;
}

#pageDropDown {
  color: #3699FF;
  background-color: #E1F0FF;
  border-color: transparent;
}

.mw-300 {
  min-width: 300px;
}
.empty-table-message {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.header-title {
    margin-left: -25px !important;
    height: 65px !important;
}

.mt-moins-15px {
  margin-top: -15px;
}

.bg-auth-img {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

.min-h-400 {
  min-height: 400px;
}

.font-size-big-title {
  font-size: 2.8rem;
}

.table-no-padding td {
  padding: 0px 0.75rem !important;
}

.matching-firstname {
  font-size: 14px;
  font-weight: 600;
  color: #3699FF;
}

.matching-passed-missions{
  font-size: 12px;
  color:rgba(126, 130, 153, 0.6);
}
.matching-city{
  font-size: 12px;
  font-weight: 600;
  color:rgba(126, 130, 153, 1);
}

.matching-avatar{
  height: 100px;
    border-radius: 5px;
}

.connectScore{
  width: 50px;
  height: 50px;
}

.btn-cv-pulse {
  height: 30px !important;
  width: 100% !important;
  margin-top: 5px;
  margin-left: 16px;
}

.text-cv-pulse {
  font-style: normal;
  margin-left: 5px;
}

.pushed{
  position: absolute;
  bottom: 20px;
}

.dropzone-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2;
  border-radius: 2;
  border-color: gray;
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  outline: none;
  transition: border .24s ease-in-out;
  height: 550px;
  .custom-dropzone{
    h3{
      color:get($aside-menu-config, base, main-blue);
    }
    p{
      font-weight: 500;
      font-size: 12px;
      margin: 0;
      color:rgba(126, 130, 153, 0.6);
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.dropzone-container-sm{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2;
  border-radius: 2;
  border-color: gray;
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  outline: none;
  transition: border .24s ease-in-out;
  .custom-dropzone{
    h3{
      color:get($aside-menu-config, base, main-blue);
    }
    p{
      font-weight: 500;
      font-size: 12px;
      margin: 0;
      color:rgba(126, 130, 153, 0.6);
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.table-int thead th {
  background-color: #F7DEE0 !important;
}

.table-int tbody td {
  background-color: #FFF !important;
}

.static-gif:hover {
  opacity:0;
}

.static-gif{
  position:absolute;

}

.gif-container:hover {
  .static-gif{
    opacity:0;
  }
}

.gif-wrapper{
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.input-range__slider {
  appearance: none;
  background: get($aside-menu-config, base, main-blue);
  border: 1px solid get($aside-menu-config, base, main-blue);
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem; }
  .input-range__slider:active {
    transform: scale(1.3); }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none; }

.input-range__slider-container {
  transition: left 0.3s ease-out; }

.input-range__label {
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute; }

.input-range__label--min {
  left: 0; }

.input-range__label--max {
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -1.8rem; }

.input-range__label-container {
  left: -50%;
  position: relative; }
  .input-range__label--max .input-range__label-container {
    left: 50%; }

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out; }
  .input-range--disabled .input-range__track {
    background: #eeeeee; }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background:get($aside-menu-config, base, main-blue); 
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }

/*# sourceMappingURL=index.css.map */


.avatar-container {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .card{
    width: 50;
    height: 50;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    background: #fff;
    border: none;
    animation: open .5s;
  }

  @keyframes open {
    0%  {background-position: 166% -25%, 220% -69%;}
    100%{background-position: 120% -5%, 200% -40%;}
  }
  form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .field{
    margin: 5px;
    display: flex;
    flex-direction: column;
    }
  input[type="file"] {
    display: none;
  }
  
  .custom-file-upload {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;
    background: get($aside-menu-config, base, main-blue);
    margin-bottom: 25px;
  }
  
  .img-wrap{
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    left: auto;
    border-radius: 50%;
  }

  .img-upload:before{
    content: "\f093";
    font-size: 90px;
    position: absolute;
    padding-top: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color:get($aside-menu-config, base, main-blue);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    opacity: 0;
    transition: .5s ease;
    background-color: #fff;
  }

  .img-upload:hover:before{
   opacity: 1;
  }

  img {
    width: auto;
    height: 100%; 
  }
  
  label{
    text-transform: uppercase;
    font-weight: 700;
    color: #676767;
  }
  
  input{
    border-radius: 15px;
    border: 1px solid #b7b7b7;
    padding: 5px 5px 5px 10px;
    font-size: 18px;
    transition: 0.2s;
    background-color: red;
  }
  input:focus{
    outline: none;
    border: 1px solid get($aside-menu-config, base, main-blue);
  }
  input::placeholder{
    color: #bebebe;
  }
  .name{ 
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #676767;
    max-width: 220px;
    overflow-wrap: break-word;
  }
  .status{
    text-align: center;
    max-width: 220px;
    overflow-wrap: break-word;
  }

  .file-input-button{
    color: #FFFFFF;
    background-color: #3699FF;
    border-color: #3699FF;
    display: inline-block;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.42rem;
    &:hover{
      cursor: pointer;
    }
  }
  .save{
    font-weight: 600;
    left: -20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 20px;
    box-shadow: 20px 0px 40px 0px  get($aside-menu-config, base, main-blue);
  }
  .edit{
    color: #fff;
    width: 180px;
  }

  button:focus{
    outline: none;
  }
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  
  
  .fa-instagram{
    position: absolute;
    color: #79f1a4;
    top: 3%;
    right: 2%;
    font-size: 38px;
  }
  .fa-instagram:hover{
    font-size: 42px;
    color: #caff81;
    transition: all .1s linear;
    cursor: pointer;
  }

}

.file-input-button{
  color: #FFFFFF!important;
  background-color: #3699FF;
  border-color: #3699FF;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.42rem;
  &:hover{
    cursor: pointer;
  }
}


.dropzone-container-xs{

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  border: "none";
  justify-content: center;
  background-color: rgba(0,0,0,0.06);
  border-radius: 20px;
  height: 150px;
  .custom-dropzone{
    h3{
      color:get($aside-menu-config, base, main-blue);
    }
    p{
      font-weight: 500;
      font-size: 12px;
      margin: 0;
      color:rgba(126, 130, 153, 0.6);
      span{
        color:rgba(126, 130, 153, 1);
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.image-circle-wrapper {
  background-color: get($aside-menu-config, base, main-blue);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    g{
      color: white;
      fill: white
    }
  }
}

.break {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: rgba(126, 130, 153, 0.6);
}

.break::before,
.break::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(126, 130, 153, 0.6);
}


.break:not(:empty)::before {
  margin-right: .25em;
}

.break:not(:empty)::after {
  margin-left: .25em;
}

.dashed {
  background-color: white;
} 

.table-wrapper{
  tbody{
    td{border: none;}
    tr{
      border-radius: 5px;
      border-top: 10px solid white;
      background-color:white;

    }
    tr:not(.dashed):nth-child(even){
      background-color:transparent;
      border-bottom: none;
      border-top: none;
      border: none!important;
     }
     tr:not(.dashed):nth-child(odd){
      background-color:transparent;
      border-bottom: none;
      border-top: none;
     }
     tr:nth-last-child(-n+2) { /* les trois derniers enfants */
      background-color: transparent;
    }


  }
 
}

.even-row {
  padding: 0!important;      background-color:rgba(225, 240, 255, 0.2);
      border-bottom: none;
      border-top: none;
}


.odd-row {  padding: 0!important;

  background-color:rgba(137, 196, 244, 0.2);
  border-bottom: none;
  border-top: none;
}

.fulfilled-row {
  padding: 0;

  background-color:rgba(137, 80, 252, 0.1);
  border-bottom: none;
  border-top: none;
}

.test {background-color: transparent;}

.hidden {
  display: none;
}

.reset-expansion-style {
  padding: 0!important;
}

.doc-preview {
  cursor: pointer;
}

.background-gray {
  background: #EEF0F8;
}

.modal-90w{
  max-width: 1500px;
}


  
  .docs-container{
    display: flex;
    flex-direction: column;
    z-index: 6;
  }

@include media-breakpoint-down(sm) {
  .doc-infos {
    margin-top: 0;  
  }
	.margin-x-10{
    margin: 0 10px 0 10px;
  }

  .dropzone-container-xs{
    margin: 10px 10px 0 10px;


}
}
@include media-breakpoint-between(md, lg) {

	
  .dropzone-container-xs{
    margin: 10px 10px 0 10px;
    width: 240px;
  }

}
@include media-breakpoint-up(xl) {

  .docs-container{
    display: flex;
    flex-direction: row;
  }

}
@media screen and (min-width: 1570px) {
  .margin-x-10{
    margin: -120px 10px 0 10px;
  }
  .dropzone-container-xs{
    width: 240px;
  }

}

.menu-icone-width {
  width: 15px;
}

.form-control{
  height: auto;
}

.doc-infos {
  z-index: 5;  
}

.white{
  color:white
}

.table-clickable{
  cursor: pointer;
}

.disabled-div
{
  pointer-events: none;
  opacity: 0.5;
}

.card-home {
  background-color: transparent;
  padding: 5px 5px 0px 15px
}

.align-right{
  text-align: right;
}

.footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}